/**
 * Logo
 * module
 */

.logo
    background-image url('/images/benangerheim-logo.svg')
    background-position top left
    background-repeat no-repeat
    background-size 100%
    display block
    height 0
    overflow hidden
    padding 30% 0 0 100%
    position relative
    width 100%

    &:before,
    &:after
        animation-name logoFadeIn
        animation-duration .9s
        animation-iteration-count 1
        animation-fill-mode both
        background-color $color-base
        content ''
        display block
        position absolute
        transform scaleY(0)
        transform-origin 0% 100% 0px

    &:before
        animation-delay 3s
        height 45%
        left 0
        top 0
        width 30%

    &:after
        animation-delay 2.4s
        height 63%
        right 0
        top 35%
        width 91%


@keyframes logoFadeIn
    from
        transform scaleY(1)

    to
        transform scaleY(0)
