/**
 * Socials
 * module
 */

.socials
    list-style none
    margin 0
    padding 0
    
    &__social
        display inline
        padding 0 .18em

    &__link
        border .18em solid $color-dark
        display inline-block
        color $color-dark
        padding .36em
        text-decoration none

        background-position center
        background-repeat no-repeat
        height 0
        overflow hidden
        padding 1.8em 0 0 1.8em
        position relative
        width 0

        &:after
            background-color $color-primary
            bottom 0
            content ''
            display block
            left 0
            position absolute
            right 0
            top 60%
            transform-origin 0% 100% 0px
            transform scaleY(0)
            transition transform .36s ease-out
            z-index -1

        &:hover:after
            transform scaleY(1)
            transition transform .18s ease-in-out

        &.-instagram
            background-image url('/images/icon-instagram.svg')

        &.-facebook
            background-image url('/images/icon-facebook.svg')
