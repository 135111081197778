/**
 * Newsletter
 * module
 */

.newsletter
    clearfix()
    background-color $color-dark
    color $color-base
    margin 0 $gutter 0 0
    padding 30vh 0 30vh $gutter
    position relative
    z-index 1

    &:before
        background-color $color-base
        bottom 0
        content ''
        display block
        left 0
        position absolute
        top 0
        right 0
        z-index 2
        transform scaleX(1)
        transform-origin 0% 50% 0px
        animation-name newsletterFadeIn
        animation-duration .6s
        animation-iteration-count 1
        animation-delay .3s
        animation-fill-mode both
        animation-play-state paused

    &.is-in-view:before
        animation-play-state running

    &__title
        column(10/12)

    &__content
        clearfix()
        span(11/12)
        clear both

    &__subscribe
        clearfix()
        background-color transparent
        position relative

    &__form:after
        content ''
        display block
        border-left 2px solid $color-dark
        position absolute
        top 50%
        left $gutter
        right $gutter

    &__input
        background-color $color-base
        border none
        border-bottom ($gutter/3) solid $color-base
        box-shadow none
        font-weight bold
        color $color-dark
        outline none
        padding $gutter $gutter ($gutter/3*2)
        transition border-color .36s ease-in-out

        &::placeholder
            color $color-light
            font-weight normal

        &:focus
            border-color $color-primary

        // NOT valid, NOT empty, NOT in focus
        &:invalid:not(:focus):not(:placeholder-shown)
            border-color red

    &__submit
        background-color $color-base
        border none
        color $color-dark
        font-weight bold
        padding $gutter 0
        text-align center
        transition background-color .6s ease-in-out

        &:hover,
        &:focus
            background-color $color-primary
            transition background-color .36s ease-in-out

    &__input,
    &__submit 
        stack()
        appearance none
        border-radius 0
        margin-bottom ($gutter/3)

    &__message
        padding ($gutter/3) 0


    @media screen and (min-width 30em)
    
        &__title
            column(10/12, $offset: 2/12)
            
        &__content 
            column(9/12, $offset: 2/12)


    @media screen and (min-width 36em)
    
        &__title
            column(4/12, $offset: 2/12)

        &__subscribe
            background-color $color-base

        &__input,
        &__submit 
            unstack()
            margin-bottom 0

        &__input
            span(5/12)

        &__submit
            span(2/12)
            text-align center

        &__form:after
            bottom 18%
            top 18%
            bottom 18%
            left 42%
            right auto
            transform rotate(30deg)
            transform-origin 50% 50% 0px


    @media screen and (min-width 52em)

        &__content
            column(6/12, $offset: 5/12)


    @media screen and (min-width 66em)

        &__content
            column(5/12, $offset: 5/12)


@keyframes newsletterFadeIn

    from
        transform scaleX(1)

    to
        transform scaleX(0)
