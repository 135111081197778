/**
 * Animations
 * base
 */

@keyframes scaleIn
  0%
    transform scaleX(0)

  100%
    transform scaleX(1)

@keyframes scaleInOut
  0%
    transform scaleX(0)

  59%
    transform scaleX(1)

  61%
    transform scaleX(1)

  100%
    transform scaleX(0)

@keyframes blaze
  0%
    transform scaleX(0)
    transform-origin 0% 50% 0px

  59%
    transform scaleX(1)
    transform-origin 0% 50% 0px

  61%
    transform scaleX(1)
    transform-origin 100% 50% 0px

  100%
    transform scaleX(0)
    transform-origin 100% 50% 0px

@keyframes fadeIn
  0%
    opacity 0

  60%
    opacity 0

  61%
    opacity 1

  100%
    opacity 1


.fadeIn
    animation-delay 0s
    position relative

    &:after
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        background-color $color-dark
        content ''
        display block
        transform scaleX(0)
        transform-origin 0% 50% 0px
        animation-name blaze
        animation-duration .6s
        animation-iteration-count 1
        animation-delay inherit
        animation-fill-mode both

    &__content
        display inline-block
        opacity 1
        animation-name fadeIn
        animation-duration .6s
        animation-iteration-count 1
        animation-delay inherit
        animation-fill-mode both

    &.-view:after,
    &.-view &__content
          animation-play-state paused

    &.-short
        animation-delay .75s

    &.-medium
        animation-delay 1.5s

    &.-long
        animation-delay 3s

    &.-right
        &:after
            animation-direction reverse

    &.-color-primary
        &:after
          background-color $color-primary


.is-in-view .fadeIn.-view
    &:after,
    .fadeIn__content
      animation-play-state running


