/**
 * Typography
 */


/** Headlines **/

h2
  font-size 12vw
  line-height 1

  @media screen and (min-width 21em)
    font-size 7.5vw

  @media screen and (min-width 36em)
    font-size 4.5vw

h4
  font-size 1.09em

  @media screen and (min-width 21em)
    font-size 1.2em


/** Block elements **/

p
  font-size 1em
  margin 0 0 1em


/** Inline elements **/

.style

  &--regular
    font-weight 200

  &--underline
    display inline-block
    position relative

    &:after
      background-color $color-primary
      bottom .09em
      content ''
      height .42em
      left -.18em
      position absolute
      right -.18em
      z-index -1

    &.-fadeIn
      &:after
        transform-origin 0% 50% 0px
        animation-name scaleIn
        animation-duration .36s
        animation-iteration-count 1
        animation-delay inherit
        animation-fill-mode both


/** Hidden **/

.hidden
  display inline-block
  height 0
  overflow hidden
  width 0


/** Selection **/      

::selection
  background-color $color-primary
  color $color-dark
