/**
 * Hero
 * module
 */

.hero
    min-height 100vh
    margin 0 0 $gap
    padding $gap $gutter 9vh
    position relative

    &:before,
    &:after
        content ''
        display block
        position absolute
        top $gutter
        bottom $gutter
        width 4em
        z-index 2

    &:before
        right 0
        width 100%
        background-image url('/images/hero-angerheim.svg')
        background-repeat no-repeat
        background-position bottom right
        background-size auto 90%
        z-index -1

        @media screen and (min-width 33em)
            background-image url('/images/hero-background.svg')

    // &:before
    //     // background-color red
    //     right 0
    //     background-image url('/images/hero-angerheim.svg')
    //     background-repeat no-repeat
    //     background-position bottom right
    //     background-size 100% auto

    // &:after
    //     // background-color green
    //     right 3em
    //     width 6em
    //     background-image url('/images/hero-ben.svg')
    //     background-repeat no-repeat
    //     background-position bottom right
    //     background-size 100% auto

    &__content
        clearfix()
        padding 0

    &__logo
        column(8/12)
        margin-bottom 18vh
        margin-top 9vh

    &__subtitle
        stack()
        clear both

    &__title
        stack()
        column(10/12, $offset: 2/12)

    &__skip-to-content
        outline none
        position relative
        text-decoration none
        transform translateY(0)
        transition transform .9s ease-out
        z-index 100

        &:active,
        &:after:active
            outline none

        &:hover
            transform translateY($gutter/2)
            transition transform .6s ease-in-out

        &:after
            animation-name heroTeaser
            animation-duration 1.8s
            animation-delay 3s
            animation-iteration-count 1
            animation-fill-mode both
            color $color-dark
            content '\25BC'
            display inline-block
            font-size 1.8em
            outline none
            padding ($gutter/1.8)
            position relative


    @media screen and (min-width 21em)
    
        &__title
            column(7/12, $offset: 2/12)

        &__skip-to-content
            bottom 0
            position absolute
            left 0

    @media screen and (min-width 36em)

        &__logo
            column(4/12)
            margin-bottom 18vh
            margin-top 9vh

        &__subtitle,
        &__title
            unstack()
            column(5/12)

        &__subtitle
            clear both
            padding 2.1vw 0 0

        &__skip-to-content
            bottom 4.5vh
            left 39%


    @media screen and (min-width 45em)

        &__logo
            column(3/12, $offset: 2/12)
            margin-bottom 18vh
            margin-top 9vh
    
        &__subtitle,
        &__title
            column(4/12)

        &__subtitle
            clear both

        &__skip-to-content
            left 33%


@keyframes heroTeaser

    from
        transform translateY($space)
        opacity 0

    to
        transform translateY(0)
        opacity 1
