/**
 * Footer
 * module
 */

.footer
    clearfix()
    padding $gutter $gutter $gap

    p
        font-size .75em
        line-height 1.25
        padding .36em 0

    @media screen and (min-width 36em)

        &__socials
            column(3/12)

        &__about,
        &__contact
            column(4/12)

    @media screen and (min-width 45em)

        &__socials
            column(3/12, $offset: 2/12)

        &__about,
        &__contact
            column(3/12)
