/**
 * Border box
 * base
 */

*,
*:before,
*:after
  -moz-box-sizing border-box
  -webkit-box-sizing border-box
  box-sizing border-box
