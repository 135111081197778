/**
 * Link
 * module
 */

p a
    border-bottom 0.15em solid
    color inherit
    display inline-block
    text-decoration none
    position relative
    
    &:before
        background-color $color-primary
        bottom 0
        content ''
        display block
        left -.24em
        position absolute
        right -.24em
        top 0
        transform scaleX(0)
        transform-origin 0% 50% 0px
        transition transform .6s ease-out
        z-index -1
        
    &:hover:before,
    &:active:before
        transform scaleX(1)
        transition transform .36s ease-in-out
        outline 0
